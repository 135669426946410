import { useEffect, useState } from 'react'
import { storageKeys } from '../lib/constants'
import { isDateLessThanTwoWeeks } from '../utils/utils'

const useDelayedMountedState = (delay) => {
  let didDonateDate, isBannerClosedInSession
  if (typeof window !== `undefined`) {
    didDonateDate = window.localStorage.getItem(storageKeys.DID_DONATE_DATE)
    isBannerClosedInSession = window.sessionStorage.getItem(
      storageKeys.IS_BANNER_CLOSED
    )
  }

  let stateToSet = true

  const donationDateLessThanTwoWeeks = isDateLessThanTwoWeeks(didDonateDate)

  if (isBannerClosedInSession === 'true') {
    stateToSet = false
  }

  if (didDonateDate && donationDateLessThanTwoWeeks) {
    stateToSet = false
  }

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(stateToSet), delay)
    return () => clearTimeout(timeout)
  }, [])

  return [isMounted, setIsMounted]
}

export default useDelayedMountedState
