import moment from 'moment'

export const isDateLessThanTwoWeeks = (dateIsoString) => {
  let isLessThanTwoWeeks = undefined

  const today = moment()
  const dateToCheck = moment(dateIsoString)

  const diff = today.diff(dateToCheck, 'days')

  if (!isNaN(diff) && diff < 14) {
    isLessThanTwoWeeks = true
  } else {
    isLessThanTwoWeeks = false
  }

  return isLessThanTwoWeeks
}
