import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import CookieConsent from 'react-cookie-consent'

import DonationBanner from '../components/donationBanner'
import useDelayedMountedState from '../hooks/useDelayedMountedState'

const Layout = ({ children }) => {
  const {
    allContentfulCookieConsentContent: { nodes },
  } = useStaticQuery(graphql`
    query LayQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulCookieConsentContent {
        nodes {
          consentText {
            consentText
          }
          buttonLabel
        }
      }
    }
  `)
  const [consentContent] = nodes
  const [isBannerMounted, setIsMounted] = useDelayedMountedState(30000)
  return (
    <>
      {children}
      <CookieConsent
        location="none"
        buttonText={consentContent.buttonLabel}
        containerClasses={`pl-3 pr-3`}
        cookieName="myAwesomeCookieName2"
        buttonStyle={{
          background: '#9c698d',
          color: '#fff',
          border: '1px solid #FFF',
          borderRadius: '0.25rem',
          marginLeft: 10,
        }}
        style={{
          background: '#9c698d',
          bottom: isBannerMounted ? '90px' : '0px',
        }}
      >
        {consentContent.consentText.consentText}
      </CookieConsent>
      {isBannerMounted && <DonationBanner setIsMounted={setIsMounted} />}
    </>
  )
}

export default Layout
