import React from 'react'
import { Link, useStaticQuery } from 'gatsby'
import { storageKeys } from '../lib/constants'

const DonationBanner = ({ setIsMounted }) => {
  const data = useStaticQuery(graphql`
    query donationBannerQuery {
      allContentfulDonationBanner {
        edges {
          node {
            donateButton
            noThanksButton
            alreadyDidButton
            childContentfulDonationBannerCallForDonationsTextNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)

  const { allContentfulDonationBanner } = data
  const [donationBanner] = allContentfulDonationBanner.edges

  const handleBannerClose = (e) => {
    e.preventDefault()
    setIsMounted(false)
    window.sessionStorage.setItem(storageKeys.IS_BANNER_CLOSED, 'true')
  }

  const handleAlreadyDidButton = (e) => {
    e.preventDefault()
    setIsMounted(false)
    const today = new Date().toISOString()
    window.localStorage.setItem(storageKeys.DID_DONATE_DATE, today)
  }

  const handleTakeToDonationPage = (e) => {
    e.preventDefault()
    setIsMounted(false)
    const today = new Date().toISOString()
    window.localStorage.setItem(storageKeys.DID_DONATE_DATE, today)
    window.location.href = '/spenden'
  }

  return (
    <div
      className="p-3"
      style={{
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#C1D6F7',
      }}
    >
      <p
        className="mb-0"
        dangerouslySetInnerHTML={{
          __html:
            donationBanner.node
              .childContentfulDonationBannerCallForDonationsTextNode
              .childMarkdownRemark.html,
        }}
      ></p>
      <div>
        <a href="#" onClick={handleTakeToDonationPage} className="mr-4">
          {donationBanner.node.donateButton}
        </a>

        <a href="#" onClick={handleAlreadyDidButton} className="mr-4">
          {donationBanner.node.alreadyDidButton}
        </a>
        <a href="#" onClick={handleBannerClose} className="mr-4">
          {donationBanner.node.noThanksButton}
        </a>
      </div>
    </div>
  )
}

export default DonationBanner
